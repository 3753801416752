import { i18nMarkTranslate } from "src/components/i18n";
import { CalendarDate } from "@gocardless/flux-react";

import { FormWithAdditionalEmail } from "./ContactDetails/AdditionalEmailField/AdditionalEmailField";

export enum DDCollectionMethod {
  FACILITIES_MANAGEMENT = "facilities",
  MANAGED_ADMINISTRATION = "managed",
}

export enum MigrationType {
  FULL = "full",
  PARTIAL = "partial",
}

export interface BulkChangeRequestForm extends FormWithAdditionalEmail {
  isFMProvider: "true" | "false";
  isIncorporatedEntity: "true" | "false";
  bulkChangeDeedForm: File | undefined;
  registeredEmail: string;
  ddCollectionMethod: DDCollectionMethod;
  sponsorBank: string;
  migrationType: MigrationType;
  numberOfCustomers: number;
  sun: string;
  sunFullName: string;
  sunShortName: string;
  finalCollectionDate: Partial<CalendarDate>;
  hasPartnerIntegration: "true" | "false";
  isSunAuddisLive: "true" | "false";
  migrationDate: Partial<CalendarDate>;
  firstCollectionDate: Partial<CalendarDate>;
  staggeredMigration: "true" | "false";
}
export interface BulkChangeRequestStepCallbacks {
  onSubmit: () => void;
}

export const ddCollectionMethodToText = (): Record<
  DDCollectionMethod,
  string
> => ({
  [DDCollectionMethod.FACILITIES_MANAGEMENT]: i18nMarkTranslate(
    "Using another Direct Debit bureau (Facilities Management)"
  ),
  [DDCollectionMethod.MANAGED_ADMINISTRATION]: i18nMarkTranslate(
    "Through a sponsor bank (Managed Administration)"
  ),
});
