import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  ButtonVariant,
  ColorPreset,
  FontWeight,
  JustifyContent,
  PlainButton,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { FC } from "react";
import { Trans } from "@lingui/macro";
import { ZENDESK_SUPPORT_FORMS } from "src/common/constants/zendesk";
import { ZendeskLink } from "src/components/ui/ZendeskLink/ZendeskLink";
import { verificationIsTakingLongerThanMaximumExpected } from "src/components/routes/Setup/optimised-verification-status/helpers/verificationIsTakingLongerThanMaximumExpected";

import { RequiredActionStatusIcon } from "../../common/RequiredActionStatusIcon";
import {
  VERIFICATION_IN_REVIEW_STATES,
  VERIFICATION_PENDING_STATES,
} from "../../constants/VerificationStates";

interface AdditionalDetailProps {
  additionalDetail?: JSX.Element;
  status: RequiredActionsStatus;
  lastTransitionTime?: string;
}

const getDefaultAdditionalDetail = (lastTransitionTime?: string) => {
  if (lastTransitionTime) {
    const lastTransitionDate = new Date(lastTransitionTime);
    const isTakingLongerThanExpected =
      verificationIsTakingLongerThanMaximumExpected(lastTransitionDate);

    if (isTakingLongerThanExpected) {
      return (
        <Trans>
          This is taking longer than usual. Please reach out to support{" "}
          {SupportLink}.
        </Trans>
      );
    }
  }

  return (
    <Trans>
      This usually takes 1 working day, but could take up to 3 working days to
      complete.
    </Trans>
  );
};

const AdditionalDetail: React.FC<AdditionalDetailProps> = ({
  additionalDetail,
  status,
  lastTransitionTime,
}) => {
  const itemIsInReview = VERIFICATION_IN_REVIEW_STATES.includes(status);

  if (!additionalDetail && !itemIsInReview) {
    return null;
  }

  return (
    <Text size={1} color={ColorPreset.TextOnLight_03} spaceAbove={0.25}>
      {additionalDetail ?? getDefaultAdditionalDetail(lastTransitionTime)}
    </Text>
  );
};

const SupportLink = (
  <ZendeskLink
    formId={ZENDESK_SUPPORT_FORMS.verification}
    variant={ButtonVariant.InlineUnderlined}
    data-tracking-label="Verification status page support request"
  >
    <Trans>here</Trans>
  </ZendeskLink>
);

export const StatusItem: FC<{
  title: JSX.Element;
  additionalDetail?: JSX.Element;
  status: RequiredActionsStatus;
  requiredActionId?: string;
  lastTransitionTime?: string;
}> = ({
  title,
  additionalDetail,
  status,
  requiredActionId,
  lastTransitionTime,
}) => (
  <Box>
    <Box
      layout="flex"
      justifyContent={JustifyContent.SpaceBetween}
      alignItems={AlignItems.Center}
    >
      <Box layout="flex" flexDirection="column" spaceAfter={0.5}>
        {[
          ...VERIFICATION_IN_REVIEW_STATES,
          ...VERIFICATION_PENDING_STATES,
        ].includes(status) && requiredActionId ? (
          <PlainButton
            decoration="underline"
            weight={FontWeight.SemiBold}
            preset={TypePreset.Body_01}
            color={ColorPreset.TextOnLight_01}
            onClick={() =>
              document
                .getElementById(requiredActionId)
                ?.scrollIntoView({ behavior: "smooth" })
            }
          >
            {title}
          </PlainButton>
        ) : (
          <Text
            weight={FontWeight.SemiBold}
            preset={TypePreset.Body_01}
            color={ColorPreset.TextOnLight_01}
          >
            {title}
          </Text>
        )}
        <AdditionalDetail
          additionalDetail={additionalDetail}
          status={status}
          lastTransitionTime={lastTransitionTime}
        />
      </Box>
      <RequiredActionStatusIcon status={status} />
    </Box>
  </Box>
);
