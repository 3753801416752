import {
  ResponsiveValue,
  CSSRulesFunction,
  useTheme,
  Box,
} from "@gocardless/flux-react";
import Image from "next/legacy/image";

import { useI18n, UserLocale } from "../i18n";
import successPlusGraphBacsDE from "../../assets/images/bacs/de-DE/success-plus-graph.png";
import successPlusGraphBacsES from "../../assets/images/bacs/es-ES/success-plus-graph.png";
import successPlusGraphBacsFR from "../../assets/images/bacs/fr-FR/success-plus-graph.png";
import successPlusGraphBacsGB from "../../assets/images/bacs/en-GB/success-plus-graph.png";
import successPlusGraphSepaDE from "../../assets/images/sepa_core/de-DE/success-plus-graph.png";
import successPlusGraphSepaES from "../../assets/images/sepa_core/es-ES/success-plus-graph.png";
import successPlusGraphSepaFR from "../../assets/images/sepa_core/fr-FR/success-plus-graph.png";
import successPlusGraphSepaGB from "../../assets/images/sepa_core/en-GB/success-plus-graph.png";

export type LocalisedImageName =
  | "SuccessPlusGraphBACS"
  | "SuccessPlusGraphSEPA_CORE";

type ImageURLs = Record<UserLocale, string>;

const registry: Record<LocalisedImageName, ImageURLs> = {
  SuccessPlusGraphBACS: {
    [UserLocale.DE_DE]: successPlusGraphBacsDE,
    [UserLocale.EN_GB]: successPlusGraphBacsGB,
    [UserLocale.ES_ES]: successPlusGraphBacsES,
    [UserLocale.FR_FR]: successPlusGraphBacsFR,
  },
  SuccessPlusGraphSEPA_CORE: {
    [UserLocale.DE_DE]: successPlusGraphSepaDE,
    [UserLocale.EN_GB]: successPlusGraphSepaGB,
    [UserLocale.ES_ES]: successPlusGraphSepaES,
    [UserLocale.FR_FR]: successPlusGraphSepaFR,
  },
};

export interface LocalisedImageProps extends ImageStyleProps {
  name: LocalisedImageName;
  alt: string;
}

interface ImageStyleProps {
  width?: ResponsiveValue<number | string>;
  height?: ResponsiveValue<number | string>;
}

const maxImagesSize = {
  width: 1665,
  height: 1392,
};

const imageStyle: CSSRulesFunction<ImageStyleProps> = (
  theme,
  { width = "auto", height = "auto" }
) => [
  theme.responsive(width, (value) => ({
    width: value,
  })),
  theme.responsive(height, (value) => ({
    height: value,
  })),
];

const LocalisedImage: React.FC<LocalisedImageProps> = (props) => {
  const { name, width, height, alt } = props;
  const [locale] = useI18n();
  const { theme } = useTheme();
  const imagePath = registry[name][locale];
  return (
    <Box css={imageStyle(theme, { width, height })}>
      <Image {...maxImagesSize} src={imagePath} alt={alt} />
    </Box>
  );
};

export default LocalisedImage;
