import {
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Dialog,
  FontWeight,
  H2,
  HoverEffect,
  JustifyContent,
  P,
  PlainButton,
  PlainLink,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { Route, getRouteURL } from "src/common/routing";
import { useState } from "react";
import { useSegment } from "src/components/third-parties/Segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { ZendeskLink } from "src/components/ui/ZendeskLink/ZendeskLink";
import { ZENDESK_SUPPORT_FORMS } from "src/common/constants/zendesk";

import { useSetupData } from "../../common/hooks/useSetupData";
import { getBankAccountHolderDetails } from "../helpers/getBankAccountHolderDetails";

interface HavingTroubleDialogProps {
  bankAccountNumberEnding?: string;
  sourceComponent: string;
}

export const HavingTroubleDialog: React.FC<HavingTroubleDialogProps> = ({
  bankAccountNumberEnding,
  sourceComponent,
}) => {
  const { sendEvent } = useSegment();
  const { creditorDetails } = useSetupData();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = () => setIsDialogOpen((previous) => !previous);

  if (
    !creditorDetails ||
    !creditorDetails.creditor_type ||
    !creditorDetails.detail
  ) {
    return null;
  }

  const handleDialogOpen = () => {
    sendEvent(TrackingEvent.VERIFICATION_STATUS_PAGE_HAVING_TROUBLE_CLICKED, {
      source: sourceComponent,
    });
    toggleDialog();
  };

  const bankAccountHolderDetails = getBankAccountHolderDetails(
    creditorDetails.creditor_type,
    creditorDetails.detail
  );

  const {
    isIndividual,
    creditorName,
    personName,
    tradingName: tName,
  } = bankAccountHolderDetails;

  const tradingName = <Text className="fs-mask">{tName}</Text>;
  const name = (
    <Text className="fs-mask">{isIndividual ? personName : creditorName}</Text>
  );

  const accountClosureLink = (
    <PlainLink
      textDecoration="underline"
      href={getRouteURL({
        route: Route.AccountClosure,
      })}
      effect={HoverEffect.TextDecoration}
    >
      <Trans>request account closure</Trans>
    </PlainLink>
  );

  const contactUsLink = (
    <ZendeskLink
      formId={ZENDESK_SUPPORT_FORMS.verification}
      variant={ButtonVariant.InlineUnderlined}
      data-tracking-label="Verifications request support"
    >
      <Trans>contact us</Trans>
    </ZendeskLink>
  );

  const addBankAccountLink = (
    <PlainLink
      textDecoration="underline"
      href={getRouteURL({
        route: Route.BankDetails,
      })}
      effect={HoverEffect.TextDecoration}
    >
      <Trans>add a different bank account</Trans>
    </PlainLink>
  );
  const amendBusinessDetailsLink = (
    <PlainLink
      textDecoration="underline"
      href={getRouteURL({
        route: Route.BusinessDetails,
      })}
      effect={HoverEffect.TextDecoration}
    >
      <Trans>amend your business details</Trans>
    </PlainLink>
  );

  return (
    <Box className="fs-unmask">
      <PlainButton
        weight={FontWeight.SemiBold}
        preset={TypePreset.Body_01}
        decoration="underline"
        spaceAbove={2}
        spaceBelow={1}
        onClick={handleDialogOpen}
      >
        <Trans>Having trouble?</Trans>
      </PlainButton>
      <Dialog
        className="fs-unmask"
        open={isDialogOpen}
        aria-labelledby="having-trouble-dialog"
        closeAction={{
          label: <Trans>Close</Trans>,
          onClose: toggleDialog,
        }}
        header={
          <H2 preset={TypePreset.Heading_02}>
            <Trans>Having trouble?</Trans>
          </H2>
        }
        footer={
          <Box layout="flex" justifyContent={JustifyContent.FlexEnd}>
            <Button
              variant={ButtonVariant.PrimaryOnLight}
              size={ButtonSize.Sm}
              onClick={toggleDialog}
            >
              <Trans>Go back</Trans>
            </Button>
          </Box>
        }
      >
        <P
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
          spaceBelow={1}
        >
          <Trans>I&apos;m unable to do this at the moment</Trans>
        </P>
        <P preset={TypePreset.Body_01} spaceBelow={1.5}>
          <Trans>
            You can return to this page at any time. Your progress has been
            saved, and we’ve emailed you a link to return to this page.
          </Trans>
        </P>
        {bankAccountNumberEnding ? (
          <>
            <P
              preset={TypePreset.Body_01}
              weight={FontWeight.SemiBold}
              spaceBelow={1}
            >
              {tName ? (
                <Trans>
                  My bank account holder name does not match my name ({name}) or
                  trading name ({tradingName})
                </Trans>
              ) : (
                <Trans>
                  My bank account holder name does not match my name ({name})
                </Trans>
              )}
            </P>
            <P preset={TypePreset.Body_01} spaceBelow={1.5}>
              <Trans>
                We need the name of the bank account to match the business
                details you&apos;ve provided so we can verify it for use with
                this account. If your bank account is under a different name,
                you may wish to {addBankAccountLink} or{" "}
                {amendBusinessDetailsLink}.
              </Trans>
            </P>
          </>
        ) : null}
        <P
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
          spaceBelow={1}
        >
          <Trans>I&apos;m unable to provide this proof</Trans>
        </P>
        <P preset={TypePreset.Body_01} spaceBelow={1.5}>
          <Trans>
            If you are unable to provide this proof we will not be able to
            verify your account for payments. If you do not wish to continue
            using GoCardless and would prefer not to receive verification
            reminders, follow this link to {accountClosureLink}.
          </Trans>
        </P>
        <P
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
          spaceBelow={1}
        >
          <Trans>I need further help</Trans>
        </P>
        <P preset={TypePreset.Body_01}>
          <Trans>Please {contactUsLink} for support.</Trans>
        </P>
      </Dialog>
    </Box>
  );
};
